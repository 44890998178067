// 申请换货页面
<template>
  <div class="sld_apply_barter">
    <div class="apply_refund_top flex_row_end_center" @click="batchSelGoods"
      v-if="batchList.data && batchList.data.length > 0">
      <img src="@/assets/order/add.png" alt="" />
      <span>{{ L["添加/编辑"] }}</span>
      <span>{{ L["（同订单商品可批量申请）"] }}</span>
      <!-- <span>（已选7件商品）</span> -->
    </div>
    <!-- 换货商品信息 start -->
    <refund-goods :refundOrderDetail="refundOrderDetail.data" v-if="batchGoods.data && batchGoods.data.length == 0">
    </refund-goods>
    <!-- 换货商品信息 end -->
    <div v-if="batchGoods.data && batchGoods.data.length > 0">
      <refund-goods :refundOrderDetail="batchGoodsItem" v-for="(batchGoodsItem, batchGoodsIndex) in batchGoods.data"
        :key="batchGoodsIndex"></refund-goods>
    </div>
    <!-- 换货详细信息 start -->
    <div class="apply_refund_info">
      <!-- 申请换货数量 start -->
      <div class="refund_reasons flex_row_start_center marginBottom20">
        <div class="refund_title"><span>*</span>申请换货数量：</div>
        <div class="refund_right">
          <el-input type="number" v-model="bart_val" maxlength="200" show-word-limit
            placeholder="请输入换货数量" class="refund_inp" @change="barter_num" @blur="handleApply"></el-input>
            <p>最大可换货数量：{{refundDetail.data.number}}</p>
        </div>
      </div>
      <!-- 申请换货数量 end -->
      <!-- 换货原因 start -->
      <div class="refund_reasons flex_row_start_center marginBottom20">
        <div class="refund_title"><span>*</span>换货原因：</div>
        <el-select placeholder="请选择换货原因" v-if="cancelList.data && cancelList.data.length > 0"
          @change="changeCancel($event)" v-model="curCancelReason" id="elSelect">
          <el-option v-for="cancelItem in cancelList.data" :key="cancelItem.reasonId" :label="cancelItem.content"
            :value="cancelItem.reasonId">
          </el-option>
        </el-select>
      </div>
      <!-- 换货原因 end -->
      <!-- 换货说明 start -->
      <div class="refund_instruction flex_row_start_start marginBottom20">
        <div class="refund_title">{{ L["问题描述"] }}：</div>
        <el-input type="textarea" v-model="refundInstruction" maxlength="200" show-word-limit
          :placeholder="L['请输入问题描述(选填)']"></el-input>
      </div>
      <!-- 换货说明 end -->
      <!-- 上传凭证 start -->
      <div class="upload_voucher flex_row_start_start marginBottom20">
        <div class="refund_title">{{ L["上传凭证"] }}：</div>
        <div>
          <div class="upload_count">总共上传<span style="color: red;">{{upLoadFile.data.length}}</span>/5张图片</div>
          <div class="upload">
            <el-upload :action="uploadImgUrl" list-type="picture-card" :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove" limit="5" :data="upload_data" name="file" :on-success="uploadImg"
              :on-exceed="exceedHandle" id="elUpload" :on-change="handleEditChange" :class="{ hide: hideUploadBtn }">
              <template #default>
                <i class="el-icon-plus"></i>
              </template>
            </el-upload>
            <el-dialog v-model="dialogVisible" lock-scroll="false" top="10vh" width="60%">
              <div style="overflow: auto; width: 100%; display: flex; justify-content: center;">
                <img :src="dialogImageUrl" alt="" style="max-height: 700px;">
              </div>
            </el-dialog>
          </div>
        </div>


      </div>
      <!-- 上传凭证 end -->
    </div>
    <!-- 换货详细信息 end -->
    <div class="apply_refund_btn" @click="submitApplyRefund">
      {{ L["确定"] }}
    </div>

    <!-- 批量选择商品的弹框 start -->
    <!-- <el-dialog width="620px" v-model="batchSelModel" append-to-body :title="L['批量售后商品选择']" lock-sroll="false">
      <div class="batch_selModel">
        <div class="batch_default">
          <div class="batch_default_pre flex_row_start_center">
            <img src="@/assets/order/sel.png" alt="" class="batch_default_sel" />
            <div :style="{ backgroundImage: 'url(' + defaultGoods.data.productImage + ')' }" class="batch_default_img"></div>
            <div class="batch_default_des">
              <p>{{ defaultGoods.data.goodsName }}</p>
              <p>{{ defaultGoods.data.specValues }}</p>
            </div>
            <el-input-number v-model="defaultGoods.data.editNum" @change="handleEditDefaultNum" :min="1"
              :max="defaultGoods.data.productNum > 999 ? 999 : defaultGoods.data.productNum" label="描述文字"
              :class="edit_batch_num" :disabled="orderState==20"></el-input-number>
          </div>
        </div>
        <div class="batch_default">
          <div class="batch_default_pre flex_row_start_center"
            v-for="(batchGoodsItem, batchGoodsIndex) in batchGoodsList.data" :key="batchGoodsIndex">
            <img src="@/assets/order/sel.png" alt="" class="batch_default_sel" v-if="batchGoodsItem.sel"
              @click="selGoods(batchGoodsItem.productId)" />
            <img src="@/assets/order/no_sel.png" alt="" class="batch_default_sel" v-if="!batchGoodsItem.sel"
              @click="selGoods(batchGoodsItem.productId)" />
            <div :style="{ backgroundImage: 'url(' + batchGoodsItem.productImage + ')' }" class="batch_default_img"></div>
            <div class="batch_default_des">
              <p>{{ batchGoodsItem.goodsName }}</p>
              <p>{{ batchGoodsItem.specValues }}</p>
            </div>
            <el-input-number v-model="batchGoodsItem.editNum" @change="handleEditBatchNum" :min="1"
              :max="batchGoodsItem.productNum > 999 ? 999 : batchGoodsItem.productNum" label="描述文字"
              :class="edit_batch_num" :disabled="orderState==20"></el-input-number>
          </div>
        </div>
      </div>
      <div class="batch_model_btn flex_row_center_center">
        <span @click="batchCancel">{{ L["取消"] }}</span>
        <span @click="batchOk">{{ L["确定"] }}</span>
      </div>
    </el-dialog> -->
    <!-- 批量选择商品的弹框 end -->
  </div>
</template>

<script>
  import { reactive, getCurrentInstance, ref, onMounted, watchEffect } from "vue";
  import { ElMessage, ElInput } from "element-plus";
  import RefundGoods from "../../../components/RefundGoods";
  import { apiUrl } from "../../../utils/config";
  import { useRoute, useRouter } from "vue-router";
import { createLogger } from 'vuex';
  export default {
    components: {
      RefundGoods,
      ElInput,
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const route = useRoute();
      const router = useRouter();
      const refundOrderDetail = reactive({ data: {} }); //换货商品信息
      const upload_data = reactive({
        source: "afterSale",
      });
      const cargoStatuslList = reactive({
        data: [
          //货物状态
          {
            content: L["未收到货"],
            value: "0",
            cargoStatusCurId: 0,
          },
          {
            content: L["已收到货"],
            value: "1",
            cargoStatusCurId: 1,
          },
        ],
      });
      const applyTypeList = reactive({
        //申请类型列表
        data: [
          {
            content: L["仅换货"],
            value: "0",
          },
          {
            content: L["换货退货"],
            value: "1",
          },
        ],
      });
      const curCargoStatus = ref("0"); //货物状态，默认选中第一个（未收到货）
      const returnAmount = ref(""); //换货金额
      const applyNum = ref(1); //申请件数
      const refundDetail = reactive({ data: {} }); //换货详情
      const cancelList = reactive({ data: [] }); //取消原因列表
      const curCancelReason = ref(""); //当前选中的取消原因
      const curCancelReasonId = ref(-1); //当前选中的取消原因的id
      const refundInstruction = ref(""); //换货说明
      const bart_val = ref(1); //换货数量
      const uploadImgUrl = apiUrl + "/v3/oss/common/upload"; //图片上传路径
      const orderState = ref(20); //orderState:订单状态，20，待发货 30,待收货
      const serviceType = ref("0"); //选择服务类型 0：仅换货 1，换货退货
      const cargoStatusCurId = ref(0); //当前已选择的货物状态id
      const curEditRefundAmount = ref("");
      const batchSelModel = ref(false); //批量申请换货弹框
      const curApplyType = ref("0"); //当前申请类型 0：仅换货 1：换货退货 2.换货
      const batchList = reactive({ data: [] }); //批量换货商品列表
      const defaultGoods = reactive({ data: {} }); //默认选中的商品
      const batchGoodsList = reactive({ data: [] }); //同订单其他的商品
      const batchGoods = reactive({ data: [], ids: [] }); //批量选择后的商品列表
      const editAmountShow = ref(false); //修改金额是否显示
      const orderProductId = ref(410); //订单商品id
      const dialogVisible = ref(false); //图片预览弹框是否显示
      const dialogImageUrl = ref(""); //图片预览图片地址
      const upLoadFile = reactive({ data: [] }); //用于传给后端的文件列表
      onMounted(() => {
        orderState.value = route.query.orderState;
        orderProductId.value = route.query.orderProductId;
        getRefundOrderDetail();
      });
      watchEffect(() => {
        //监听申请件数的变化
        if(bart_val.value>refundDetail.data.number){
          ElMessage('不可超出商品件数');
          bart_val.value = refundDetail.data.number
          return
        }
          if (bart_val.value > 10) {
            bart_val.value = 10;
            ElMessage(L["不可超出最大申请数量"]);
          }
      });
      //获取换货订单货品详情
      const getRefundOrderDetail = () => {
        let params = {
          orderProductId: orderProductId.value,
        };
        proxy
          .$get(
            "v3/business/front/after/sale/apply/getOrderProductDetail",
            params
          )
          .then((res) => {
            if (res.state == 200) {
              refundOrderDetail.data = res.data;
            } else {
              ElMessage.error(res.msg);
            }
          })
          .then(() => {
            getRefundDetail();
            getCancelList();
            getBatchOrderDetail();
          });
      };
      //获取计算换货换货信息，计算结果未此次最多可退金额
      const getRefundDetail = (type, Infos, num) => {
        let params = {};
        if (type == "batch") {
          params = {
            orderSn: refundOrderDetail.data.orderSn,
            orderProductInfos: Infos.join(","), //退换的订单货品列表，格式为：id1-num1,id2-num2...num为空时表示此订单货品全部退换
          };
        } else {
          //单个商品
          let orderProductInfos = "";
          orderProductInfos =
            refundOrderDetail.data.orderProductId +
            "-" +
            (num || refundOrderDetail.data.productNum);
          params = {
            orderSn: refundOrderDetail.data.orderSn,
            orderProductInfos: orderProductInfos,
          };
        }
        proxy
          .$get("v3/business/front/after/sale/apply/countReturnMoney", params)
          .then((res) => {
            if (res.state == 200) {
              let result = res.data;
              if(num){
                Object.keys(result).forEach(i=>{
                  if(i != 'number' || !refundDetail.data.number){
                    refundDetail.data[i] = result[i]
                  }
                })
              }else{
                refundDetail.data = result;
              }
              returnAmount.value = "" + result.maxReturnMoney;
              curEditRefundAmount.value = refundDetail.data.maxReturnMoney;
              applyNum.value = "" + result.number;
            } else {
              ElMessage.error(res.msg);
            }
          });
      };
      const editAmount = () => {
        editAmountShow.value = true;
      };
      //获取换货原因
      const getCancelList = () => {
        let params = {
          type: 109,
        };
        //原因类型：101-违规下架；102-商品审核拒绝；103-入驻审核拒绝；104-会员取消订单；105-仅换货-未收货；106-仅换货-已收货；107-换货退货原因；108-商户取消订单
        proxy.$get("v3/system/front/reason/list", params).then((res) => {
          if (res.state == 200) {
            let result = res.data;
            cancelList.data = result || [];
          } else {
            ElMessage.error(res.msg);
          }
        });
      };
      //选择换货原因
      const changeCancel = (e) => {
        cancelList.data && cancelList.data.map((cancelItem) => {
          if (cancelItem.reasonId == e) {
            curCancelReason.value = cancelItem.content;
            curCancelReasonId.value = cancelItem.reasonId;
          }
        });

      };
      //批量选择商品
      const batchSelGoods = () => {
        batchSelModel.value = true;
        defaultGoods.data.editNum = defaultGoods.data.currNum;
        batchGoodsList.data.forEach(item=>{
          if(batchGoods.ids.indexOf(item.goodsId) != -1){
            item.sel = true;
          }else{
            item.sel = false;
          }
          item.editNum = item.currNum;
        })
      };
      //获取换货商品选择的商品数据
      const getBatchOrderDetail = () => {
        // let params = {
        //   orderSn: refundOrderDetail.data.orderSn,
        //   orderProductId: refundOrderDetail.data.orderProductId,
        // };
        // proxy
        //   .$get("v3/business/front/after/sale/apply/getOrderProductList", params)
        //   .then((res) => {
        //     if (res.state == 200) {
        //       let result = res.data;
        //       batchList.data = result;
        //       // 主商品
        //       defaultGoods.data = batchList.data[0];
        //       defaultGoods.data.currNum = batchList.data[0].productNum;
        //       defaultGoods.data.sel = true; //sel是否选中
        //       // 同订单其他商品
        //       batchList.data.splice(0, 1);
        //       batchGoodsList.data = batchList.data;
        //       batchGoodsList.data &&
        //         batchGoodsList.data.map((batchGoodsItem) => {
        //           batchGoodsItem.currNum = batchGoodsItem.productNum;
        //           batchGoodsItem.sel = false;
        //         });
        //     } else {
        //       ElMessage.error(res.msg);
        //     }
        //   });
      };
      //批量换货，商品状态的选择
      const selGoods = (productId) => {
        batchGoodsList.data &&
          batchGoodsList.data.map((batchGoodsItem) => {
            if (batchGoodsItem.productId == productId) {
              batchGoodsItem.sel = !batchGoodsItem.sel;
            }
          });
      };
      //批量换货，取消
      const batchCancel = () => {
        batchSelModel.value = false;
        // batchGoods.data = [];
        // getBatchOrderDetail();
      };
      //批量换货，确定
      const batchOk = () => {
        batchSelModel.value = false;
        defaultGoods.data.currNum = defaultGoods.data.editNum;
        //批量多个商品
        let batchSelList = [];
        let ids = [];
        batchSelList.push(defaultGoods.data);
        batchGoodsList.data.map((batchGoodsItem) => {
          batchGoodsItem.currNum = batchGoodsItem.editNum
          if (batchGoodsItem.sel) {
            batchSelList.push(batchGoodsItem);
            ids.push(batchGoodsItem.goodsId);
          }
        });
        batchSelList.forEach((batchSelItem) => {
          batchSelItem.infopre =
            batchSelItem.orderProductId + "-" + batchSelItem.currNum;
        });
        let Infos = [];
        batchSelList.forEach((batchSelItem) => {
          Infos.push(batchSelItem.infopre);
        });
        //同订单其他商品
        batchGoods.data = batchSelList;
        batchGoods.ids = ids;
        batchGoodsList.data = batchList.data;

        getRefundDetail("batch", Infos);
      };
      //图片预览
      const handlePictureCardPreview = (response) => {
        dialogVisible.value = true;
        dialogImageUrl.value = response.url;
      };
      //上传图片
      const uploadImg = (response) => {
        if (response.state == 200) {
          upLoadFile.data.push(response.data.path);
        }
        if (upLoadFile.data.length == 5) {
          document.getElementById('elUpload').children[1].style.display = 'none'
        }

      };
      const handleRemove = (file) => {
        if (file.status == 'success') {
          upLoadFile.data = upLoadFile.data.filter(item => item != file.response.data.path)
        }
        if (upLoadFile.data.length < 5) {
          document.getElementById('elUpload').children[1].style.display = 'inline-block'
          hideUploadBtn.value = false
        }

      }

      //上传图片，图片超出限制
      const exceedHandle = () => {
        ElMessage('最多上传5张！')
      };
      //确定提交申请
      const submitApplyRefund = () => {
        if (curCancelReason.value == "") {
          ElMessage('请选择换货原因');
        } else {

          let productList = [];
            //单个商品
            let orderProduct = {};
            orderProduct.orderProductId = refundOrderDetail.data.orderProductId;
            orderProduct.afsNum = applyNum.value;
            productList.push(orderProduct);
          // if (batchGoods.data.length == 0) {
          // } else {
          //   //批量申请
          //   productList =
          //     batchGoods.data &&
          //     batchGoods.data.map(function (batchGoodsItem) {
          //       return {
          //         orderProductId: batchGoodsItem.orderProductId,
          //         applyNum: batchGoodsItem.currNum,
          //       };
          //     });
          // }
          let params = {
            orderSn: refundOrderDetail.data.orderSn,
            afsType: 2, //申请类型（换货服务单类型，1-退货换货单（需关联处理换货金额），2-换货单，3-仅换货单）
            applyReasonContent: curCancelReason.value, //换货原因
            afsDescription: refundInstruction.value, //问题描述
            applyImage: upLoadFile.data.join(","), //上传凭证图片,多个图片用英文逗号隔开
            productList: productList, //订单货品列表
            goodsState: 1, //货物状态
            finalReturnAmount: 0, //换货金额
          };
          params = JSON.stringify(params);
          proxy
            .$post("v3/business/front/after/sale/apply/submit", params, "json")
            .then((res) => {
              if (res.state == 200) {
                ElMessage.success(res.msg);
                router.go(-1);
              } else {
                ElMessage.error(res.msg);
              }
            });
        }
      };

      const handleBlur = () => {
        if (curEditRefundAmount.value.toString().split('.').length) {
          let tmp = curEditRefundAmount.value.toString().split('.')[0]
          curEditRefundAmount.value = tmp.length > 2 ? curEditRefundAmount.value.toFixed(2) : curEditRefundAmount.value
        }
      }

      const handleApply = () => {
        let reg = /\./g
        let reg0 = /0+\d/

        if (reg.test(bart_val.value) || reg0.test(bart_val.value) || bart_val.value <= 0) {
          bart_val.value = 1
        }
        if(bart_val.value)

        refundOrderDetail.data.currNum = bart_val.value;
        getRefundDetail(null,null,bart_val.value);
      }
      const hideUploadBtn = ref(false);
      const handleEditChange = (file, fileList)=>{
        hideUploadBtn.value = fileList.length >= 5;
      }

      return {
        L,
        refundOrderDetail,
        cargoStatuslList,
        curCargoStatus,
        returnAmount,
        applyNum,
        refundDetail,
        cancelList,
        changeCancel,
        curCancelReason,
        refundInstruction,
        uploadImgUrl,
        submitApplyRefund,
        serviceType,
        cargoStatusCurId,
        curEditRefundAmount,
        batchSelGoods,
        batchSelModel,
        applyTypeList,
        curApplyType,
        batchList,
        defaultGoods,
        batchGoodsList,
        selGoods,
        batchCancel,
        batchOk,
        batchGoods,
        orderState,
        editAmountShow,
        editAmount,
        orderProductId,
        dialogImageUrl,
        dialogVisible,
        handlePictureCardPreview,
        upload_data,
        uploadImg,
        upLoadFile,
        getCancelList,
        exceedHandle,
        handleRemove,
        handleBlur,
        handleApply,
        bart_val,
        handleEditChange,
        hideUploadBtn
      };
    },
  };
</script>

<style lang="scss">
  @import "../../../style/base.scss";
  @import "../../../style/iconfont.css";
  @import "../../../style/barter.scss";

  .el-radio__label {
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }

  .el-icon-close-tip {
    display: none !important;
  }

  .el-upload-list__item-status-label {
    display: none !important;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: $colorMain2;
    background: $colorMain2;
  }

  .el-radio__input.is-checked+.el-radio__label {
    color: $colorMain2;
  }

  .el-textarea {
    width: 786px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .upload_count {
    font-size: 14px;
    margin-bottom: 10px;
  }

  #elUpload{
    flex-wrap: wrap;
  }
  .hide {
  .el-upload--picture-card {
    display: none !important;
  }
}
</style>